.aboutimagebb1 {
    background-image: linear-gradient(to left, rgba(53, 50, 48, 0.4), rgba(41, 39, 37, 0.4)), url("/public/msgimage.jpg");
    background-size: cover;
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
}
.msgimage11 {
    background-image: linear-gradient(to left, rgba(53, 50, 48, 0.4), rgba(41, 39, 37, 0.4)), url("/public/msgimage.jpg");
    background-size: cover;
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
}
.about-history{
    font-weight: bold;
        font-family: serif;
    font-size: 42px;
}
.msgimage {
    width: 100%;
    height:400px;
    border-radius: 5px;
  }
.msgsmt {
    font-weight: bold;
    padding-top: 7%;
    font-size: 45px;
    font-family: serif;
}
.aboutsmt {
    font-weight: bold;
    padding-top: 12%;
    font-size: 45px;
    font-family: serif;
}
.aboutbg2 {
    background-color: rgba(211, 211, 211, 0.2);
}
.about-h1 {
    font-family: serif;
    font-weight: bold;
}
.history1{
    font-size: 19px;
    font-family: serif;
    text-align: justify;
}
.history-span{
    font-weight: bold;
}
.about-p {
    text-align: justify;
    font-family: serif;
    font-size: 17px;
}
.about-span {
    color: #FD5F00;
}
.about-crousel{
    width: auto;
    height:500px;
}
.bghu {
    font-family: sans-serif;
    font-size: 17px;
    text-align: justify;
}

/* Media queries for different screen sizes */
@media (max-width: 1200px) {
    .aboutsmt {
        font-size: 35px;
        padding-top: 12%;
    }
    .about-p {
        font-size:10px;
    }
    .about-h1 {
        font-family: serif;
    font-size: 20px;
  
    }
   
    .bghu {
        font-size: 15px;
    }
    .carousel-item img {
        max-height: 400px;
        object-fit: cover;
    }
}

@media (max-width: 1000px) and (min-width: 765px) {
    .aboutsmt {
        font-size: 35px;
        padding-top: 12%;
    }
    .about-p {
        font-size:13px;
    }
    .about-h1 {
        font-family: serif;
    font-size: 23px;
  
    }
    .mm{
        margin-top: 10%;
    }
    .bghu {
        font-size: 15px;
    }
    .carousel-item img {
        max-height: 400px;
        object-fit: cover;
    }
}

@media (max-width: 765px) {
    .president{
        display: flex;
        flex-wrap: wrap;
    }
    .vices{
        display: flex;
        flex-wrap: wrap;
    }
    .registerar{
        display: flex;
        flex-wrap: wrap;
    }
    .aboutsmt {
        font-size: 30px;
        padding-top: 15%;
    }
    .about-p {
        font-size: 14px;
    }
    .bghu {
        font-size: 14px;
    }
    .carousel-item img {
        max-height: 300px;
        object-fit: cover;
    }
    .registrar-photo {
        width: 100%; /* Adjust this to a percentage or fixed size */
        max-width: 250px; /* Set a maximum width */
        height: auto; /* Maintain aspect ratio */
        border-radius: 10px; /* Optional: rounded corners */
        display: block; /* Ensures it behaves like a block element */
        margin: 0 auto; /* Center the image */
      }
}
