.vision-page {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  text-align: justify;
}

.header {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 250px; 
}

.header::before {
  content: '';
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  z-index: 1; 
}

.header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; 
  z-index: 0;

}

.overlay1 {
  position: relative;
  top: -10%;
  left: 15%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); 
  display: flex;
  justify-content: left;
  align-items: end;
  color: rgb(250, 244, 244);
  z-index: 1; 
}

.overlay h1 {
  margin: 0;
  font-size: 2.rem;
  display: flex;
  align-items: center;
}

.overlay .pipe {
  color: #ff0000; 
  margin-right: 10px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.vision, .mission {
  width: 48%;
  padding: 20px;
  box-shadow: 0 0 1px rgba(99, 80, 80, 0.925);
  background-color: #fff;
  border-radius: 8px;
  text-align: justify;
}
.mission1{
  font-size: 18px;
  font-family: serif;
  text-align: justify;
}
.mission2{
  font-size: 30px;
  font-family: serif;
  text-align: justify;
  font-weight: bold;
}

.vision h2, .mission h2 {
  margin-top: 0;
}

.mission ul {
  padding-left: 20px;
}
.vision-page .journey {
  margin-top: 60px;
}

.vision-page .journey h2 {
  font-size: 1.5rem;
  margin-bottom: -10%;
}

.vision-page .journey-content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  border: 9px solid #ccc;

  padding: 20px;
  background: #ccc4c4;
  border-radius: 30px;
  padding: 20px 150px; 
  max-width: 1250px;
  font-family: serif;
  font-size: 25px;
}

.vision-page .image-container {
  position: relative;
  width: 80%;
}

.vision-page .journey-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.vision-page .overlay1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(248, 244, 244);
  padding: 10px;
  text-align: center;
}

.vision-page .journey-text {
  width: 100%;
}

.vision-page .journey-text p {
  font-size: 1.6rem;
  line-height: 1.5;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .vision, .mission {
    width: 100%;
    margin-bottom: 20px;
  }

  .vision-page .journey-content {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .vision-page .image-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .vision-page .journey-text {
    width: 100%;
    text-align: justify;
    font-size: 25px;
    font-family: serif;
  }
}
