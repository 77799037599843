.yogicbg2{
    background-color: rgba(192, 178, 178, 0.2);

}
.yogic-span{
    color: #FD5F00;
  }
  .yogic-heading{
    font-weight: bold;
    font-family: serif;
  }