.bg1 {
    background-color: #ad3826;
    color: white;
    
  }
  .bg2 {
    background-color: #0D1128;
    color: white;
  }
  .carousel-item img{
    border-radius: 0px !important;
    background-size: cover !important;
    height: 530px;

  }

 