
      .ukimg {
        width: 50%;
        height:110px;
        border-radius: 20px;
        }
        .ukimg1 {
            width: 60%;
            height:140px;
            border-radius: 20px;
            }
      .accer-col{
        width: 80%;
        border-radius: 25px;
        background-color: white;
      }
      .u{
        font-family: serif;
      }