.contact{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #F2F0F0;
}
form{
    max-width: 600px;
    width: 100%;
    background: #fff;
    padding: 25px 25px 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    color: #333;
    margin: 25px;
}
form h2 {
    font-size: 30px;
    text-align: center;
    color: #FD5F00;
    font-family: serif;
    font-weight: bold;
}
form .input-box{
    margin-top: 20px;
}
.input-box .field {
width: 100%;
height: 50px;
background-color: transparent;
border: 2px solid #ddd;
outline: none;
border-radius: 6px;
padding: 15px;
font-size: 16px;
color: #333;
margin-top: 8px;
}
.input-box .field.mess{
    height: 200px;
    resize: none;
}
form button {
    width: 100%;
    height: 55px;
    background-color: #FD5F00;;
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin-top: 25px;
    transition: .5s;

}
form button:hover{
    background-color: #0D1128;
}