/* Background image sections */
.engineering-image, .diplomacse-image, .civil-image, .mechanical-image {
  background-image: linear-gradient(to left, rgba(60, 57, 55, 0.4), rgba(82, 79, 75, 0.4)), url("/public/science9.jpeg");
  background-size: cover;
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .engineering-image, .diplomacse-image, .civil-image, .mechanical-image {
    height: 300px; /* Adjust height for smaller screens */
  }
}

/* Text and image columns */
.text-column {
  font-size: 1.2rem;
  font-family: serif;
  text-align: justify;
}

/* .image-column {
  text-align: center;
  margin-top: 20px;
} */

/* .image-column img {
  width: 100%;
  height: auto;
  max-height: 400px; 
} */

/* Card styles */
.card1 {
  position: relative;
  transition: transform 0.2s;
  cursor: pointer;
  padding: 5px 10px 15px 20px;
  margin-bottom: 20px; /* Add margin for spacing between cards */
}

.card1:hover {
  transform: scale(1.05);
}

.khusbhucard-heading {
  font-family: serif;
  font-weight: bold;
}

.khusbhucard-paiding {
  font-family: serif;
  text-align: justify;
  font-size: 17px;
}

/* Page layout */
.school1-page {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
 
}

/* Vision and Mission sections */
.vision-section, .mission-section {
  background-color: #f57d0c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.8);
  border: 2px solid transparent;
  margin-bottom: 20px; /* Add margin for spacing */
}

.vision-section h2, .mission-section h2 {
  font-size: 29px;
  margin-bottom: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  color: rgb(241, 239, 233);
}

.mission-section ul {
  list-style-type: none;
  padding-left: 0;
}

.mission-section li {
  margin-bottom: 10px;
}

/* Carousel styles */
.carousel-inner img {
  border-radius: 10px;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: #333;
  border-radius: 50%;
}

.carousel-image {
  height: 430px;
  object-fit: cover;
}

/* Media queries for additional responsiveness */
@media (max-width: 992px) {
  .carousel-image {
    height: 300px;
  }

  .vision-section, .mission-section {
    padding: 15px;
  }

  .vision-section h2, .mission-section h2 {
    font-size: 24px;
  }
}

/* Adjust the container for different screen sizes */
.container {
  max-width: 1200px;
  margin: 0 auto;
}
/* electrical css*/
.program-details {
  display: flex;
  padding: 20px;
}

.program-overview {
  flex: 2;
  margin-right: 20px;
  
}

.program-info {
  flex: 1;
  background-color: #002147; /* Dark blue background */
  color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 20px;
}

.info-box {
  margin-bottom: 20px;
}

.info-box p {
  margin: 5px 0;
}
.program-tracks {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.program-tracks h2 {
  margin-bottom: 20px;
  font-size: 34px;
}

.tracks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.track {
  flex: 1 1 calc(22.333% - 5px); /* Adjusts to fit three items per row */
  background-color: #f5f5f5;
  padding: 10px;
  border: 2px solid #ffc107; /* Example border color */
  text-align: center;
  font-size: 18px;
  cursor: default;
  transition: background-color 0.3s;
}

.track:hover {
  background-color: #ffe082; /* Example hover effect */
}
.main-page {
  text-align: center;
}
.School1-page .main-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.School1-page .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: auto;
  height: auto;
}

.School1-page .info-sections {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 35%;
}

.School1-page .card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
}
.School1-page .card img {
  width: 100%;
  height: auto;
  display: block;
  filter: brightness(50%);
}
.School1-page .card .card-body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
}
.School1-page .card .card-body h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
}
.School1-page .card .card-body p {
  font-size: 1rem;
}
.School1-page .info-section {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .School1-page .main-page {
    flex-direction: column;
    align-items: center;
  }

  .School1-page .cards,
  .School1-page .info-sections {
    width: 100%;
  }

  .School1-page .cards {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .card {
    width: 100%;
  }
}
.info-sections {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 300px;
}

.info-section {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.info-section h3 {
  margin: 0 0 10px 0;
}
.program-highlights {
  background-color: #0d2a48; /* Dark blue background color */
  color: white; /* White text color */
  padding: 20px;
  margin-top: 20px;
}

.program-highlights h2 {
  text-align: center;
  margin-bottom: 20px;
}

.highlight-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.highlight-item {
  width: 45%;
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.highlight-item::before {
  content: "➤"; /* Arrow symbol */
  margin-right: 10px;
  color: orange; /* Arrow color */
}
/* Default layout (Desktop) */

/* Mobile view adjustments */
@media (max-width: 768px) {
  .program-details {
    display: block;
  }

  .program-overview {
    order: 1;
  }

  .program-info {
    order: 2;
  }
}
