.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.mb-3 {
  margin-bottom: 1rem;
}

.d-flex {
  display: flex;
}

.justify-content-left {
  justify-content: flex-start;
}

.mr-2 {
  margin-right: 0.5rem;
}

.contact-details h3 {
  margin-top: 1rem;
}

hr {
  margin: 1rem 0;
}

.rotate-icon {
  display: inline-block;
  transform: rotate(100deg); /* Adjust the degree of rotation as needed */
}

.map iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  padding: 10px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, transform 200ms;
}

.btn:hover {
  background: darkorange; /* Change to your desired hover color */
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}